<template>
  <v-container class="page">
    <Headline
      :title="username"
      icon="mdi-account-outline"
    />
    <UserProfile
      :targetUserUid="$store.state.base.user.uid"
      @cancel="$router.back()"
    />
  </v-container>
</template>

<script lang="js">
import Headline from '@/common/Headline';
import UserProfile from '@/acc/components/UserProfile.vue';

export default {
  name: 'MyProfile',
  components: {
    Headline,
    UserProfile
  },
  computed: {
    username () {
      return (
        this.$store.state.base.user.firstname + ' ' + this.$store.state.base.user.surname
      );
    }
  }
};
</script>

<style scoped>
</style>

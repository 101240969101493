<template>
  <v-card>
    <v-tabs v-model="tab">
      <v-tab href="#profile">
        {{ $t('acc.userAccount') }}
      </v-tab>
      <v-tab
        href="#pwd"
        v-if="targetUser.uid === $store.state.base.user.uid"
      >
        {{ $t('acc.password') }}
      </v-tab>
      <v-tab
        href="#rights"
        v-if="isAdminMode"
      >
        {{ $t('accAdmin.rights') }}
      </v-tab>
      <!--<v-tab
        href="#groups"
        v-if="isAdminMode"
      >
        {{ $t('accAdmin.groups') }}
      </v-tab>-->
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="profile">
        <UserAccount
          :targetUser="targetUser"
          :isAdminMode="isAdminMode"
          @userUpdated="userUpdated"
          @reinvite="reinvite"
          @cancel="cancel()"
        />
      </v-tab-item>
      <v-tab-item
        value="pwd"
        v-if="targetUser.uid === $store.state.base.user.uid"
      >
        <ChangePassword
          @cancel="cancel()"
          :isAdminMode="isAdminMode"
        />
      </v-tab-item>
      <v-tab-item
        value="rights"
        v-if="isAdminMode"
      >
        <UserRights
          :userRoles="userRoles"
          :targetUserUid="targetUserUid"
          @save="updateRolesAndGroups"
          @cancel="cancel()"
        />
      </v-tab-item>
      <!--<v-tab-item
        value="groups"
        v-if="isAdminMode"
      >
        <UserGroups
          :userGroups="userGroups"
          @save="updateRolesAndGroups"
          @cancel="cancel()"
        />
      </v-tab-item>-->
    </v-tabs-items>
  </v-card>
</template>

<script lang="js">
import UserAccount from '@/acc/components/UserAccount.vue';
import ChangePassword from '@/acc/components/ChangePassword.vue';
import UserRights from '@/accAdmin/components/UserRights.vue';
// import UserGroups from '@/accAdmin/components/UserGroups.vue';

import { accHelper } from '@/acc/js/accHelper';
import { isProcessStateOK } from '@/base/js/ProcessDataHelper';

export default {
  name: 'UserProfile',
  components: {
    UserAccount,
    ChangePassword,
    UserRights
    // UserGroups
  },
  props: {
    targetUserUid: { type: String, required: true }
    // targetOrgUid: { type: String, required: true },
    // isAdminMode: { type: Boolean, default: () => { return false; } }
  },
  data () {
    return {
      targetUser: accHelper.copyUser(),
      userRoles: [],
      userGroups: []
    };
  },
  computed: {
    tab: {
      set (tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get () {
        return this.$route.query.tab;
      }
    },
    isAdminMode () {
      return this.$route.path.startsWith('/accAdmin/');
    },
    targetOrgUid () {
      if (this.isAdminMode) return this.$store.state.base.admin.administratedOrg.uid;
      return this.$store.state.base.org.uid;
    },
    username () {
      return (
        this.targetUser.firstname + ' ' + this.targetUser.surname
      );
    }
  },
  mounted () {
    if (this.$store.state.base.admin.administratedOrg.uid) {
      if (!this.isAdminMode) {
      // create temporary copy of current user
        this.targetUser = accHelper.copyUser(this.$store.state.base.user);
      } else {
        const ioData = {
          targetUser: this.targetUserUid,
          targetOrg: this.targetOrgUid
        };
        this.$restClient.callProcess('accAdmin', 'userGet', ioData, true).then(
          (processData) => {
            if (isProcessStateOK(processData)) {
              this.targetUser = processData.ioData.targetUser;
              this.$emit('setPageMode', 'edit', this.targetUser.firstname + ' ' + this.targetUser.surname);
              for (let role of processData.ioData.userRoles) {
                if (role !== 'basicUser') {
                  this.userRoles.push(role);
                }
              }
              this.userGroups = processData.ioData.userGroups;
            } else {
              this.$globals.Info.open(processData.processState.messages);
            }
          }
        );
      }
    } else {
      this.$router.push('/accAdmin/users');
    }
  },
  methods: {
    userUpdated (updatedUser) {
      this.$logger.debug('acc', 'User updated');
      this.targetUser = updatedUser;
      if (this.$store.state.base.user.uid === updatedUser.uid) {
        // update current user
        this.$store.state.base.user = accHelper.copyUser(updatedUser);
      }
    },
    reinvite (user) {
      this.$emit('reinvite', user);
    },
    updateRolesAndGroups (selectedRoles, selectedGroups, unselectedGroups) {
      const ioData = {
        targetUserList: [ this.targetUserUid ],
        targetOrg: {
          uid: this.targetOrgUid
        },
        targetRoleList: selectedRoles || this.userRoles,
        targetGroupList: selectedGroups || this.userGroups
      };
      if (selectedRoles != null) this.userRoles = selectedRoles;
      if (selectedGroups != null) this.userGroups = selectedGroups;
      if (unselectedGroups != null) ioData.unselectedGroupList = unselectedGroups;

      this.$restClient.callProcess('accAdmin', 'userUpdateRolesAndGroups', ioData, true).then(
        (processData) => {
          if (isProcessStateOK(processData)) {
            this.$logger.debug('acc', 'roles and groups updated sucessfully.');
          } else {
            this.$globals.Info.open(processData.processState.messages);
          }
        }
      );
    },
    cancel () {
      if (this.isAdminMode) this.$router.push('/accAdmin/users');
      else this.$router.back();
    }
  }
};
</script>

<style scoped>
</style>

<template>
  <v-container class="page">
    <amplify-authenticator>
      <amplify-sign-in
        :federated="{}"
        :hide-sign-up="true"
        slot="sign-in"
        id="signincomp"
      >
        <template v-slot:federated-buttons="{}">
        </template>
      </amplify-sign-in>
    </amplify-authenticator>
    <!--<v-switch
        v-model="isRemainSignedIn"
        :label="$t('base.signin.remainSignedIn')"
      ></v-switch>-->
    <div hidden>
      <v-text-field id="mu" />
      <v-text-field id="mp" />
      <v-btn
        id="mbtn"
        @click="callAmplify"
      >monitoringbtn</v-btn>
    </div>
    <!--<v-btn @click="fillMonitoringFields">MON</v-btn>-->
  </v-container>
</template>

<script lang="js">
import { onAuthUIStateChange } from '@aws-amplify/ui-components';

export default {
  name: 'Login',
  data () {
    return {
      isRemainSignedIn: false,
      unsubscribeAuth: undefined
    };
  },
  beforeCreate () {
    if (this.$store.getters.orgReady) {
      this.$router.push(this.$configHelper.getDefaultRoute('acc'));
    } else if (this.$store.getters.userReady) {
      this.$router.push('/chooseOrg');
    }
  },
  created () {
    this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
      if (authState === 'signedin') {
        if (this.$store.state.base.signedInState < 1) {
          this.$store.state.base.signedInState = 1;
          if (this.$store.state.base.firstRoute.startsWith('/acc/login')) {
            this.$store.state.base.firstRoute = '/';
          }
          this.$router.push(this.$store.state.base.firstRoute);
          this.$emit('checkAuth');
        }
      } else {
        // reset Session
        this.$store.commit('signOut');
      }
    });
  },
  beforeDestroy () {
    this.unsubscribeAuth();
  },
  methods: {
    // fillMonitoringFields () {
    //   let uf = document.getElementById('mu');
    //   uf.value = 'MoniToring';
    //   let pf = document.getElementById('mp');
    //   pf.value = 'M0niT0ring#D';
    //   let btn = document.getElementById('mbtn');
    //   btn.click();
    // },
    callAmplify () {
      let evt = document.createEvent('HTMLEvents');
      evt.initEvent('input', false, true);
      let shadow = document.getElementById('signincomp').shadowRoot;
      shadow.getElementById('username').value = document.getElementById('mu').value;
      shadow.getElementById('username').dispatchEvent(evt);
      shadow.getElementById('password').value = document.getElementById('mp').value;
      shadow.getElementById('password').dispatchEvent(evt);
      shadow.childNodes[0].getElementsByClassName('sign-in-form-footer')[0].getElementsByClassName('button')[0].click();
    }
  }
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
amplify-authenticator {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 600px;
}
</style>

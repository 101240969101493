<template>
  <v-container class="page">
    <Headline
      :title="$t('acc.register.headline')"
      icon="mdi-account-outline"
    />
    <v-card v-if="user != null">
      <v-card-text>
        <v-container class="text-left">
          <v-row>
            <v-col>
              {{ $t("acc.register.hint") }}
            </v-col>
          </v-row>
          <v-row>
            <v-col
              sm="3"
              cols="12"
            >
              <v-text-field
                v-model="user.salutation"
                :label="$t('acc.user.salutation')"
                dense
              />
            </v-col>
            <v-col
              sm="3"
              cols="12"
            >
              <v-text-field
                v-model="user.title"
                :label="$t('acc.user.title')"
                dense
              />
            </v-col>
            <v-col
              sm="6"
              cols="12"
            >
            </v-col>
            <v-col
              sm="6"
              cols="12"
            >
              <v-text-field
                v-model="user.firstname"
                :label="$t('acc.user.firstname') + $t('base.mandatory')"
                dense
              />
            </v-col>
            <v-col
              sm="6"
              cols="12"
            >
              <v-text-field
                v-model="user.surname"
                :label="$t('acc.user.surname') + $t('base.mandatory')"
                dense
              />
            </v-col>
            <v-col
              sm="6"
              cols="12"
            >
              <v-text-field
                v-model="user.email"
                :label="$t('acc.user.email')"
                :hint="$t('acc.register.emailHint')"
                dense
                disabled
                persistent-hint
              />
            </v-col>
            <v-col
              sm="6"
              cols="12"
            >
              <v-text-field
                v-model="user.username"
                :label="$t('acc.user.username') + $t('base.mandatory')"
                :hint="$t('acc.user.usernameHint')"
                dense
                persistent-hint
              />
            </v-col>
          </v-row>
          <v-row v-if="invitationKey">
            <v-col
              sm="6"
              cols="12"
            >
              <v-text-field
                v-model="pwd.new"
                :append-icon="pwd.showNew ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[pwdRule.password]"
                :type="pwd.showNew ? 'text' : 'password'"
                :label="$t('acc.newPwd') + $t('base.mandatory')"
                :hint="$t('acc.newPwdHint')"
                @click:append="pwd.showNew = !pwd.showNew"
                dense
              />
            </v-col>
            <v-col
              sm="6"
              cols="12"
            >
              <v-text-field
                v-model="pwd.confirm"
                :append-icon="pwd.showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                :type="pwd.showConfirm ? 'text' : 'password'"
                :label="$t('acc.confirmPwd') + $t('base.mandatory')"
                @click:append="pwd.showConfirm = !pwd.showConfirm"
                dense
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-checkbox
                v-model="isDataStorageConsent"
                :label="$t('acc.register.dataStorageConsentTitle') + $t('base.mandatory')"
                dense
              />
              <div
                class="pt-4"
                v-html="$t('acc.register.dataStorageConsent')"
              >
              </div>
              <!--<v-checkbox
                v-model="isDataStorageConsent"
                :label="$t('acc.register.dataStorageConsentFooter') + $t('base.mandatory')"
                class="strong"
                dense
              />-->
              <div>
                <p>Die Verarbeitung der Daten erfolgt entsprechend der <v-btn to="/privacy">Datenschutzerklärung</v-btn>. Ich habe die <v-btn to="/privacy">Datenschutzerklärung</v-btn>. zur Kenntnis genommen und akzeptiere diese.</p>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              {{ $t('base.mandatoryHint') }}
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click.native="accept()"
          color="secondary"
        >{{ $t("acc.register.acceptInvitation") }}</v-btn>
      </v-card-actions>
    </v-card>
    <v-card
      v-else
      flat
      class="pa-16"
    >
      <v-btn
        color="secondary"
        @click.native="$router.push($sessionHelper.getLoginPath())"
        x-large
      >{{ $t('base.signin.gotoSignin')}}</v-btn>
    </v-card>
  </v-container>
</template>

<script lang="js">
import Headline from '@/common/Headline';
import { isProcessStateOK, newMessage } from '@/base/js/ProcessDataHelper';
import { accHelper } from '@/acc/js/accHelper';

export default {
  name: 'UserRegistration',
  components: {
    Headline
  },
  props: {
    invitationKey: { type: String, default: () => { return null; } }
  },
  data () {
    return {
      user: null,
      pwd: {
        new: '',
        showNew: false,
        confirm: '',
        showConfirm: false
      },
      isDataStorageConsent: false,
      pwdRule: {
      // required: value => !!value || this.$t('base.required'),
        password: value => {
          // let pwdPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*?[#?!@$%^&*-])(?=.{8,})/;
          let pwdPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
          return (pwdPattern.test(value));
        }
      }
    };
  },
  computed: {
    invitedText () {
      return this.$t('acc.register.invitedText');
    }
  },
  async beforeMount () {
    if (this.invitationKey == null) {
      // init form from signed in user
      console.log('register already known user', this.$store.state.base.user);
      this.user = accHelper.copyUser(this.$store.state.base.user);
    } else {
    // remove all Cookies and sign out
      await this.$sessionHelper.signOut();
      // do registrationInit-request with invitationKey
      // let invitationKey = new URLSearchParams(window.location.search).get('ik');
      this.$logger.debug('auth', 'InvitationKey: ', this.invitationKey);

      const ioData = {
        invitationKey: encodeURIComponent(this.invitationKey)
      };

      this.$restClient.callProcess('acc', 'userRegistrationInit', ioData).then((processData) => {
        if (isProcessStateOK(processData)) {
          this.user = accHelper.copyUser(processData.ioData.targetUser);
          this.user.username = '';
          this.user.emailVerified = Date.now();
          this.$store.state.base.user.uid = this.user.uid;
          this.$store.state.base.org.uid = processData.ioData.targetOrg;
        } else {
          this.$globals.Info.open(processData.processState.messages);
        }
      });
    }
  },
  methods: {
    async accept () {
      let messages = [];
      // check for missing fields or password-erors
      if (this.user.username.trim().length === 0 ||
        this.user.firstname.trim().length === 0 ||
        this.user.surname.trim().length === 0
      ) {
        messages.push(newMessage('userError', this.$t('acc.error.missingMandatoryFields')));
      }
      if (this.user.username.includes('@')) {
        messages.push(newMessage('userError', this.$t('acc.error.invalidUsername')));
      }

      if (this.invitationKey != null) {
        if (this.pwd.new !== this.pwd.confirm) {
          messages.push(newMessage('userError', this.$t('acc.error.changePWDnewPwdsDifferent')));
        }
        if (!this.pwdRule.password(this.pwd.new)) {
          messages.push(newMessage('userError', this.$t('acc.error.changePWDnewPwdinvalid') + ': ' + this.$t('acc.newPwdHint')));
        }
      }
      if (!this.isDataStorageConsent) {
        messages.push(newMessage('userError', this.$t('acc.register.error.dataStorageConsentMissing')));
      }

      if (messages.length === 0) {
        this.user.password = this.pwd.new;
        const ioData = {
          targetUser: this.user,
          dataStorageConsent: this.isDataStorageConsent
        };

        if (this.invitationKey) {
          await this.confirmNewUser(ioData);
        } else {
          await this.confirmExistingUser(ioData);
        }
      } else {
        this.$globals.Info.open(messages);
      }
    },
    async confirmNewUser (ioData) {
      let processData = await this.$restClient.callProcess('acc', 'userRegistrationConfirm', ioData, true);
      if (isProcessStateOK(processData)) {
        this.$sessionHelper.signInManually(this.user.username, this.pwd.new).then(() => {
          if (this.$store.state.base.firstRoute.startsWith('/acc/register')) this.$store.state.base.firstRoute = '/';
          this.$router.push(this.$store.state.base.firstRoute);
          this.$emit('checkAuth');
        }).catch((err) => {
          const messages = [
            newMessage('systemError', err)
          ];
          this.$globals.Info.open(messages);
        });
      } else {
        this.$globals.Info.open(processData.processState.messages);
      }
    },
    async confirmExistingUser (ioData) {
      let processData = await this.$restClient.callProcess('acc', 'userRegistrationConfirmKnownUser', ioData, true);
      if (isProcessStateOK(processData)) {
        this.$store.state.base.user = processData.ioData.targetUser;
        this.$store.state.base.orgList = processData.ioData.orgList.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase());
        await this.$sessionHelper.setOrganisation(processData);
        if (this.$store.state.base.firstRoute.startsWith('/acc/register')) this.$store.state.base.firstRoute = '/';
        this.$router.push(this.$store.state.base.firstRoute);
        this.$emit('checkAuth');
      } else {
        this.$globals.Info.open(processData.processState.messages);
      }
    }
  }
};
</script>

<style scoped>
.strong >>> .v-label {
  font-weight: bolder;
}
</style>

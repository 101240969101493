<template>
  <v-card>
    <v-card-text
      v-if="tempUser"
      class="minContent"
    >
      <v-row>
        <v-col sm="7">
          <v-row>
            <v-col>
              <v-text-field
                v-model="tempUser.salutation"
                :label="$t('acc.user.salutation')"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="tempUser.title"
                :label="$t('acc.user.title')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="tempUser.firstname"
                :label="$t('acc.user.firstname') + $t('base.mandatory')"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="tempUser.surname"
                :label="$t('acc.user.surname') + $t('base.mandatory')"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col sm="5">
          <!--<v-row>
            <v-col
              sm="8"
              class="text-left"
            >
              <v-text-field
                v-model="tempUser.mobile"
                :label="$t('acc.user.mobilePhone')"
                :hint="$t('acc.user.mobilePhoneHint')"
                disabled
                persistent-hint
              ></v-text-field>
              <div class="text-left body-2">
                {{ mobileVerifiedText }}
              </div>
            </v-col>
            <v-col sm="4">
              <mobile-phone-control
                :user="targetUser"
                @changed="mobileChanged"
              />
            </v-col>
          </v-row>-->
        </v-col>
      </v-row>

      <v-row v-if="tempUser">
        <v-col sm="7">
          <v-row
            no-gutters
            class="body-1 pb-4"
            width="100%"
          >
            {{$t('acc.user.avatar')}}
          </v-row>
          <v-row
            no-gutters
            class="align-center cropperContainer"
          >
            <!-- Vorschau -->
            <v-avatar
              v-if="avatarBin && !editAvatar"
              size="128px"
              class="ml-6"
            >
              <v-img :src="avatarBin" />
            </v-avatar>
            <InitialLettersIcon
              v-if="avatarBin == null  && !editAvatar"
              :userName="tempUser.fullName"
              :size="128"
            />
            <preview
              v-if="editAvatar"
              class="ml-6"
              :width="128"
              :height="128"
              :image="result.image"
              :coordinates="result.coordinates"
              style="border-radius: 50%;"
            />
            <!-- Auswählen-Button -->
            <v-btn
              v-if="isMyself"
              class=" secondary--text ml-8"
              icon
              @click="$refs.file.click()"
              v-tooltip="$tooltip($t('acc.user.selectAvatar'))"
            >
              <v-icon class="large">mdi-upload</v-icon>
            </v-btn>
            <input
              hidden
              type="file"
              ref="file"
              @change="loadAvatarImage($event)"
              accept="image/*"
            >
            <v-btn
              v-if="editAvatar"
              class="ml-8 secondary--text"
              icon
              @click="resetAvatarEdit"
              v-tooltip="$tooltip($t('base.reset'))"
            >
              <v-icon class="large">mdi-close</v-icon>
            </v-btn>
            <v-btn
              v-if="(isMyself || (tempUser.avatar != null && tempUser.avatar.length > 0)) && !editAvatar"
              class="ml-8 secondary--text"
              icon
              @click="deleteAvatar"
              v-tooltip="$tooltip($t('acc.user.deleteAvatar'))"
            >
              <v-icon class="large">mdi-delete-forever</v-icon>
            </v-btn>
          </v-row>
          <!-- Cropper -->
          <v-row
            no-gutters
            class="mt-4"
            style="max-height: 600px;"
          >
            <cropper
              v-show="editAvatar"
              class="cropper"
              background-class="cropper__background"
              foreground-class="cropper__foreground"
              ref="cropper"
              :src="image.src"
              :stencilComponent="$options.components.CircleStencil"
              :debounce="100"
              :stencil-props="{
                  handlers: {},
                  movable: false,
                  scalable: false,
                  previewClass: 'cropper__stencil',
                }"
              :stencil-size="{
                  width: 246,
                  height: 246
                }"
              image-restriction="stencil"
              @change="changeAvatarPreview"
            />
          </v-row>
        </v-col>
        <v-col sm="5">
          <v-row>
            <v-col sm="8">
              <v-text-field
                v-model="tempUser.email"
                :label="$t('acc.user.email') + $t('base.mandatory')"
                :hint="$t('acc.user.emailHint')"
                XXXdisabled
                readonly
                persistent-hint
              ></v-text-field>
              <!--<div class="text-left body-2 mb-4">
                {{ emailVerifiedText }}
              </div>-->
            </v-col>
            <!--<v-col sm="4">
              <email-control
                :user="tempUser"
                @changed="emailChanged"
              />
            </v-col>-->
          </v-row>
          <v-row>
            <v-col sm="8">
              <v-text-field
                v-model="tempUser.username"
                :label="$t('acc.user.username') + $t('base.mandatory')"
                :hint="$t('acc.user.usernameHint')"
                persistent-hint
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="isAdminMode">
            <v-col
              sm="12"
              class="mt-8"
            >
              <v-chip
                @click="changeState()"
                class="mr-8"
              >
                {{ $t("acc.user.state") + " " + $t('accAdmin.userList.' + tempUser.state) }}
              </v-chip>
              <!--<v-chip
                v-if="tempUser.invitationState !== 'applied'"
                outlined
                :class="tempUser.invitationState === 'invited' ? 'success' : ''"
                @click="reinvite()"
              >
                {{ $t("acc.user.state") + " " + $t('accAdmin.userList.' + tempUser.invitationState) }}
              </v-chip>-->
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- save or cancel editing -->
    <v-card-text>
      <SaveCancelButtonBar
        class="mt-4"
        :cancel-text="$t(isAdminMode ? 'base.back' : 'base.cancel')"
        @cancel="$emit('cancel')"
        @save="save()"
      />
    </v-card-text>
  </v-card>
</template>

<script lang="js">
// import MobilePhoneControl from '@/common/MobilePhoneControl.vue';
// import EmailControl from '@/common/EmailControl.vue';
import SaveCancelButtonBar from '@/common/SaveCancelButtonBar';

import { accHelper } from '@/acc/js/accHelper';
import { ImageHelper } from '@/base/js/ImageHelper';
import { S3Helper } from '@/base/js/aws/S3Helper';
import { isProcessStateOK, newMessage } from '@/base/js/ProcessDataHelper';
import InitialLettersIcon from '@/common/InitialLettersIcon.vue';
import { Cropper, CircleStencil, Preview } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

export default {
  name: 'UserAccount',
  components: {
    SaveCancelButtonBar,
    // MobilePhoneControl,
    // EmailControl,
    InitialLettersIcon,
    Cropper,
    // eslint-disable-next-line vue/no-unused-components
    CircleStencil,
    Preview
  },
  props: {
    targetUser: { type: Object, required: true },
    isAdminMode: { type: Boolean, default: () => { return false; } }
  },
  data () {
    return {
      editAvatar: false,
      removeAvatar: false,
      result: {
        coordinates: null,
        image: null
      },
      image: {
        src: null,
        type: null
      },
      avatarBin: null,
      tempUser: null,
      rotation: 0
    };
  },
  computed: {
    mobileVerifiedText () {
      if (this.tempUser.mobile.length === 0) {
        return '';
      } else if (this.tempUser.mobileVerified.valueOf() > 0) {
        return this.$t('acc.verified');
      } else {
        return this.$t('acc.notVerified');
      }
    },
    emailVerifiedText () {
      if (this.tempUser.email.length === 0) {
        return '';
      } else if (this.tempUser.emailVerified.valueOf() > 0) {
        return this.$t('acc.user.verified');
      } else {
        return this.$t('acc.user.notVerified');
      }
    },
    isMyself () {
      return this.tempUser.uid === this.$store.state.base.user.uid;
    }
  },
  watch: {
    targetUser () {
      this.initUser();
    }
  },
  mounted () {
    if (this.targetUser) this.initUser();
  },
  methods: {
    initUser () {
      this.editAvatar = false;
      this.tempUser = accHelper.copyUser(this.targetUser);
      if (!this.tempUser.avatarBin && this.tempUser.avatar.length > 0 && this.$store.state.base.user.avatar !== '-') {
        let assets = this.$configHelper.getModuleConfigParam('acc', 'assets');
        let imageUrl = assets.url + this.tempUser.avatar;
        ImageHelper.getImage(imageUrl)
          .then((avatarBin) => {
            this.avatarBin = avatarBin;
          });
      } else {
        this.avatarBin = this.tempUser.avatarBin;
      }
    },
    changeAvatarPreview ({ coordinates, image }) {
      this.result = {
        coordinates,
        image
      };
    },
    getMimeType (file, fallback = null) {
      const byteArray = (new Uint8Array(file)).subarray(0, 4);
      let header = '';
      for (let i = 0; i < byteArray.length; i++) {
        header += byteArray[i].toString(16);
      }
      switch (header) {
        case '89504e47':
          return 'image/png';
        case '47494638':
          return 'image/gif';
        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
        case 'ffd8ffe3':
        case 'ffd8ffe8':
          return 'image/jpeg';
        default:
          return fallback;
      }
    },
    loadAvatarImage (event) {
      // Reference to the DOM input element
      const { files } = event.target;
      // Ensure that you have a file before attempting to read it
      if (files && files[0]) {
        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src);
        }
        // 2. Create the blob link to the file to optimize performance:
        const blob = URL.createObjectURL(files[0]);
        // 3. The steps below are designated to determine a file mime type to use it during the
        // getting of a cropped image from the canvas. You can replace it them by the following string,
        // but the type will be derived from the extension and it can lead to an incorrect result:
        //
        // this.image = {
        //    src: blob;
        //    type: files[0].type
        // }
        // Create a new FileReader to read this image binary data
        const reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.image" refers to the image of Vue component
          this.image = {
            // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
            src: blob,
            // Determine the image type to preserve it during the extracting the image from canvas:
            type: this.getMimeType(e.target.result, files[0].type)
          };
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsArrayBuffer(files[0]);
        this.editAvatar = true;
        this.removeAvatar = false;
      }
    },
    resetAvatarEdit () {
      this.editAvatar = false;
      this.result = { coordinates: null, image: null };
      this.image = { src: null, type: null };
    },
    deleteAvatar () {
      this.resetAvatarEdit();
      this.avatarBin = null;
      this.tempUser.avatar = '-';
      this.tempUser.avatarBin = null;
      this.removeAvatar = true;
    },
    emailChanged (email, verified) {
      this.tempUser.email = email;
      this.tempUser.emailVerified = verified;
      this.$emit('userUpdated', this.tempUser);
    },
    mobileChanged (number, verified) {
      this.tempUser.mobile = number;
      this.tempUser.mobileVerified = verified;
      this.$emit('userUpdated', this.tempUser);
    },
    changeState () {
      if (this.tempUser.state === 'active') {
        this.tempUser.state = 'inactive';
      } else { // if (this.tempUser.state === 'inactive')
        this.tempUser.state = 'active';
      }
    },
    reinvite () {
      this.$emit('reinvite', this.tempUser);
    },
    // async uploadAvatar () {
    //   let assets = this.$configHelper.getModuleConfigParam('acc', 'assets');
    //   let path = assets.pathUserAvatar + this.tempUser.uid + '_avatar';
    //   await S3Helper.uploadBase64Object(path, this.avatarBin, assets.s3config);
    //   this.tempUser.avatarBin = this.avatarBin;
    //   this.tempUser.avatar = path;
    //   this.$logger.debug('acc', 'Uploaded new avatar');
    //   this.save();
    //   // this.$emit('userUpdated', this.tempUser);
    // },
    async uploadAvatar () {
      let assets = this.$configHelper.getModuleConfigParam('acc', 'assets');
      let path = assets.pathUserAvatar + this.tempUser.uid + '_avatar';
      let { canvas } = this.$refs.cropper.getResult();
      this.avatarBin = await ImageHelper.resizeBase64Image(canvas.toDataURL(), 256, 256);
      if (this.avatarBin != null && this.avatarBin.length > 0) {
        await S3Helper.uploadBase64Object(path, this.avatarBin, assets.s3config, true);
        this.tempUser.avatarBin = this.avatarBin;
        this.tempUser.avatar = path;
        this.$logger.debug('acc', 'Uploaded new avatar');
      }
    },
    async deleteUploadedAvatar () {
      let assets = this.$configHelper.getModuleConfigParam('acc', 'assets');
      let path = assets.pathUserAvatar + this.tempUser.uid + '_avatar';
      await S3Helper.deleteObject(path, assets.s3config);
      this.tempUser.avatarBin = null;
      this.tempUser.avatar = '-';
      this.avatarBin = null;
    },
    async save () {
      let messages = [];
      this.tempUser = accHelper.copyUser(this.tempUser);
      // check for missing fields or password-erors
      if (this.tempUser.username.length === 0 ||
        this.tempUser.firstname.length === 0 ||
        this.tempUser.surname.length === 0
      ) {
        messages.push(newMessage('userError', this.$t('acc.error.missingMandatoryFields')));
      }
      if (this.tempUser.username.includes('@')) {
        messages.push(newMessage('userError', this.$t('acc.error.invalidUsername')));
      }
      if (messages.length > 0) {
        this.$globals.Info.open(messages);
        return;
      }
      if (this.editAvatar) {
        await this.uploadAvatar();
      } else if (this.removeAvatar) {
        await this.deleteUploadedAvatar();
      }
      // send data to backend
      let ioData = {
        targetUser: {
          uid: this.tempUser.uid,
          salutation: this.tempUser.salutation,
          title: this.tempUser.title,
          firstname: this.tempUser.firstname,
          surname: this.tempUser.surname,
          username: this.tempUser.username,
          mobile: this.tempUser.mobile,
          mobileVerified: this.tempUser.mobileVerified,
          email: this.tempUser.email,
          emailVerified: this.tempUser.emailVerified,
          state: this.tempUser.state,
          avatar: this.tempUser.avatar
        }
      };
      if (this.$store.state.base.admin.isAdminMode) ioData.targetOrg = this.$store.state.base.admin.administratedOrg.uid;
      else ioData.targetOrg = this.$store.state.base.org.uid;

      this.$restClient.callProcess('acc', 'userUpdate', ioData, true).then(
        (processData) => {
          if (isProcessStateOK(processData)) {
            this.editAvatar = false;
            this.removeAvatar = false;
            this.$emit('userUpdated', this.tempUser);
            this.$globals.Confirm.okDlg(this.$t('acc.user.updateData'), this.$t('acc.user.updateDataSuccess'));
            this.$logger.debug('acc', 'Saved User Account Data');
          } else {
            this.$globals.Info.open(processData.processState.messages);
          }
        }
      );
    }
  }
};
</script>

<style scoped>
.imgInput {
  height: 100%;
  max-height: 440px;
  width: 100%;
  max-width: 400px;
}
</style>

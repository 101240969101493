<template>
  <v-card>
    <v-card-text class="minContent">
      <v-text-field
        class="mt-4 scTextfield"
        v-model="oldPwd"
        :append-icon="showOldPwd ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showOldPwd ? 'text' : 'password'"
        :label="$t('acc.oldPwd')"
        @click:append="showOldPwd = !showOldPwd"
      ></v-text-field>

      <v-text-field
        class="mt-4 scTextfield"
        v-model="newPwd"
        :append-icon="showNewPwd ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[pwdRule.password]"
        :type="showNewPwd ? 'text' : 'password'"
        :label="$t('acc.newPwd')"
        :hint="$t('acc.newPwdHint')"
        @click:append="showNewPwd = !showNewPwd"
      ></v-text-field>

      <v-text-field
        class="mt-4 scTextfield"
        v-model="confirmPwd"
        :append-icon="showConfirmPwd ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showConfirmPwd ? 'text' : 'password'"
        :label="$t('acc.confirmPwd')"
        @click:append="showConfirmPwd = !showConfirmPwd"
      ></v-text-field>

    </v-card-text>
    <!-- save or cancel editing -->
    <v-card-text>
      <SaveCancelButtonBar
        class="mt-4"
        :cancel-text="$t(isAdminMode ? 'base.back' : 'base.cancel')"
        @cancel="$emit('cancel')"
        @save="save()"
      />
    </v-card-text>
  </v-card>
</template>

<script lang="js">
// import { Auth } from 'aws-amplify';
import SaveCancelButtonBar from '@/common/SaveCancelButtonBar';
import { Auth } from '@aws-amplify/auth';

export default {
  name: 'ChangePassword',
  components: {
    SaveCancelButtonBar
  },
  props: {
    isAdminMode: { type: Boolean, default: () => { return false; } }
  },
  data () {
    return {
      oldPwd: '',
      newPwd: '',
      confirmPwd: '',
      showOldPwd: false,
      showNewPwd: false,
      showConfirmPwd: false,
      pwdRule: {
      // required: value => !!value || this.$t('base.required'),
        password: value => {
          // let pwdPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*?[#?!@$%^&*-])(?=.{8,})/;
          let pwdPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
          return (
            pwdPattern.test(value)
          );
        }
      }
    };
  },
  methods: {
    save () {
      let pwdOK = false;
      let message = '';
      if (this.oldPwd.trim().length === 0 || this.newPwd.trim().length === 0) {
        message = this.$t('acc.error.changePWDemptyFields');
      } else if (this.newPwd !== this.confirmPwd) {
        message = this.$t('acc.error.changePWDnewPwdsDifferent');
      } else if (!this.pwdRule.password(this.newPwd)) {
        message = this.$t('acc.error.changePWDnewPwdinvalid') + ': ' + this.$t('acc.newPwdHint');
      } else {
        pwdOK = true;
      }

      if (pwdOK) {
        Auth.currentAuthenticatedUser().then((user) => {
          Auth.changePassword(user, this.oldPwd, this.newPwd).then((data) => {
            this.$logger.debug('auth', 'Change Password: ' + data);
            this.$globals.Confirm.okDlg(this.$t('acc.changePWDTitle'), this.$t('acc.changePWDSuccess'));
            this.$globals.Info.open([{ type: 'success', message: this.$t('acc.changePWDTitle') + ': ' + this.$t('acc.changePWDSuccess') }]);
          }).catch(err => {
            message = this.$t('acc.error.' + err.code);
            this.$globals.Confirm.okDlg(this.$t('acc.changePWDTitle'), message);
          });
        });
      } else {
        this.$globals.Confirm.okDlg(this.$t('acc.changePWDTitle'), message);
      }
    }
  }

};
</script>

<style scoped>
.scTextfield {
  max-width: 500px;
}
</style>

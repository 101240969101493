<template>
  <v-card>
    <v-card-text
      class="minContent"
      v-if="availableRoles != null"
    >
      <v-row
        v-for="(role, index) in availableRoles"
        :key="index"
      >
        <v-col>
          <v-checkbox
            v-model="selectedRoles"
            :label="$t(role.moduleUid + '.userRights.roles.' + role.uid)"
            :value="role.uid"
          ></v-checkbox>
          <component
            class="pr-0 pl-8"
            v-bind:is="role.rightsComponent"
            :ref="role.ref"
            :userRights="userRights[role.ref] || {}"
            v-show="selectedRoles.includes(role.uid)"
          ></component>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pl-11">
          {{ $t('accAdmin.userRights.userRightsHint') }}
        </v-col>
      </v-row>
    </v-card-text>
    <!-- save or cancel editing -->
    <v-card-text>
      <SaveCancelButtonBar
        class="mt-4"
        :cancel-text="$t('base.back')"
        @cancel="$emit('cancel')"
        @save="save()"
      />
    </v-card-text>
  </v-card>
</template>

<script lang="js">
import SaveCancelButtonBar from '@/common/SaveCancelButtonBar';
import { isProcessStateOK } from '@/base/js/ProcessDataHelper';

export default {
  name: 'UserRights',
  components: {
    SaveCancelButtonBar
  },
  props: {
    userRoles: { type: Array, required: true },
    targetUserUid: { type: String, required: true }
  },
  data () {
    return {
      availableRoles: null,
      selectedRoles: [],
      userRights: {}
    };
  },
  computed: {
  },
  mounted () {
    this.selectedRoles = this.userRoles.slice();
    this.getAvailableRoles();
    this.getModuleSpecificUserRights();
  },
  methods: {
    getAvailableRoles () {
      let list = [];
      for (let role of this.$store.state.base.admin.administratedOrgObjects.roleList) {
        let moduleUid = role.uid.substring(0, role.uid.length - 7);
        if (this.$configHelper.getModuleConfigParam(moduleUid, 'hasAdminPart') === true) {
          role.moduleUid = moduleUid + 'Admin';
          if (this.$configHelper.getModuleConfigParam(role.moduleUid, 'hasUserRightsComponent') === true) {
            role.ref = role.moduleUid + 'UserRights';
            role.rightsComponent = () => import('../../.generic/accCustomDialogs/' + role.moduleUid + 'UserRights.vue');
          }
          list.push(role);
        }
      }
      this.availableRoles = list;
    },
    async getModuleSpecificUserRights () {
      let ioData = {
        targetKey: this.$store.state.base.admin.administratedOrg.uid,
        context: '',
        contextKey: this.targetUserUid,
        contextFilter: 'UserRights'
      };
      this.$restClient.callProcess('baseAdmin', 'globalPropertiesGetList', ioData, true).then(async (processData) => {
        if (isProcessStateOK(processData)) {
          this.userRights = processData.ioData.globalProps || {};
        } else {
          this.$globals.Info.open(processData.processState.messages);
        }
      });
    },
    async save () {
      for (let role of this.availableRoles) {
        if (role.ref != null) {
          let userRights = this.$refs[role.ref][0].getUserRights();
          let ioData = {
            targetKey: this.$store.state.base.admin.administratedOrg.uid,
            context: role.ref,
            contextKey: this.targetUserUid,
            globalProps: userRights
          };
          this.$restClient.callProcess('baseAdmin', 'globalPropertiesCreate', ioData, true).then(async (processData) => {
            // We use create, because we don't know if Item is existing in DB (Create also works as update)
            if (isProcessStateOK(processData)) {
              this.userRights[role.ref] = userRights;
              if (this.targetUserUid === this.$store.state.base.user.uid) {
                this.$store.state[role.moduleUid].userRights = userRights; // update own rights
              }
            } else {
              this.$globals.Info.open(processData.processState.messages);
            }
          });
        }
      }
      this.$logger.debug('accAdmin', 'selected roles: ', this.selectedRoles);
      this.$emit('save', this.selectedRoles, null, null);
    }
  }
};
</script>

<style scoped>
</style>

<template>
  <v-avatar
    class="borderIcon"
    :size="size"
  >
    <span
      class="headline initialLetters"
      :style="getFontSize()"
    >{{ initialLetters() }}</span>
  </v-avatar>
</template>

<style lang="scss">
.borderIcon {
  background-color: var(--v-grey-lighten5) !important;
  border-color: var(--v-grey-lighten5) !important;

  .initialLetters {
    color: rgba(0, 0, 0, 0.54) !important;
    text-transform: uppercase;
    font-weight: 600 !important;
  }
}
</style>

<script>
export default {
  name: 'InitialLettersIcon',
  props: {
    userName: { type: String, required: true },
    size: { type: Number, default: 48 }
  },
  methods: {
    initialLetters () {
      let firstname = '';
      let surame = '';
      if (this.userName != null && this.userName.length > 0) {
        let names = this.userName.split(' ');
        if (names.length > 0) {
          firstname = names[0];
          surame = names[names.length - 1];
        }
      } else {
        // return "NN" for not named if no user name exists or user is private
        return 'NN';
      }
      let firstInitialLetter = '';
      let secondInitialLetter = '';
      if (firstname.length > 0) {
        firstInitialLetter = firstname.charAt(0);
      }
      if (surame.length > 0) {
        secondInitialLetter = surame.charAt(0);
      }
      // retrun initials
      return firstInitialLetter + secondInitialLetter;
    },
    getFontSize () {
      return 'font-size: calc(' + this.size + 'px / 2) !important;';
    }
  }
};
</script>
